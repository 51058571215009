import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import BookingDetails from '../BookingDetails/BookingDetails';
import BookingDetailsDeprecated from '../BookingDetailsDeprecated/BookingDetails';
import { SidebarViewModel } from '../../ViewModel/sidebarViewModel/sidebarViewModel';
import Login from '../Login/Login';
import { classes, st } from './Sidebar.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { Summary } from '../Summary/Summary';
import { Button } from '../Button/Button';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { SidebarDataHooks } from './constants';

export interface SidebarProps {
  viewModel: SidebarViewModel;
}

function isSidebarBelowBody(sidebar: HTMLDivElement | undefined | null) {
  const body = sidebar?.previousSibling as HTMLElement;
  return sidebar?.offsetLeft === body?.offsetLeft;
}

const Sidebar: React.FC<sidebarprops> = ({
  viewModel: {
    title,
    bookingDetailsViewModel,
    loginViewModel,
    ctaViewModel,
    summaryViewModel,
  },
}) => {
  const { onNext } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const sidebarRef = React.useRef<htmldivelement>(null);
  const shouldBeCollapsed = isSidebarBelowBody(sidebarRef.current);
  const showTitle = !!summaryViewModel;
  const { experiments } = useExperiments();
  const isCalendarA11YChangesEnabled = experiments.enabled(
    'specs.bookings.calendarA11YChanges',
  );

  return (
    <div ref="{sidebarRef}" data-hook="{SidebarDataHooks.ROOT}" className="{st(classes.root," {="" isMobile="" })}="">
      {showTitle && <sectionheader title="{title}"></sectionheader>}
      {!!bookingDetailsViewModel &&
        (isCalendarA11YChangesEnabled ? (
          <bookingdetails viewModel="{bookingDetailsViewModel}" title="{title}" isCollapsed="{shouldBeCollapsed}"></bookingdetails>
        ) : (
          <bookingdetailsdeprecated viewModel="{bookingDetailsViewModel}" title="{title}" isCollapsed="{shouldBeCollapsed}"></bookingdetailsdeprecated>
        ))}
      {!!summaryViewModel && <summary viewModel="{summaryViewModel}"></summary>}
      <button disabled="{ctaViewModel.disabled}" data-hook="{SidebarDataHooks.CTA}" className="{classes.cta}" fullWidth="{ctaViewModel.fullWidth}" loading="{ctaViewModel.loading}" onClick="{()" ==""> onNext()}
      >
        {ctaViewModel.label}
      </button>
      <login viewModel="{loginViewModel}"></login>
    </div>
  );
};

export default Sidebar;
</htmldivelement></sidebarprops>